<template>
<h2>
        L'assurance qualité
      </h2>
      <!--   /////////////////La granulation /////////////////////////// -->
      <div class="ensemble_production">
        <div class="container">
          <div class="content content_image">
            <div class="bordure"></div>
            <div class="image image_aq"></div>
          </div>
          <div class="content content_texte">
            <h3>Garantir le niveau de qualité du site</h3>
            <p>
              Le <span class="bold">Service de Management de la Qualité</span> est au plus près des équipes afin d'instaurer une politique qualité suivie et respectée de tous. Le laboratoire AJC Pharma oeuvre chaque jour pour assurer la satisfaction aux patients, à l'agence de sécurité des médicaments et à ses partenaires donneurs d'ordre.
            </p>
            <p>
              L'expertise du laboratoire AJC Pharma s'appuie sur <span class="bold">une documentation maitrisée</span> (CAPA ; CC ; BPF) et sur un partage constant avec ses clients, notamment pendant les <span class="bold">audits réguliers</span>.
            </p>

          </div>
        </div>
        </div>
</template>

<script>
export default {
  name: "AQ-component",
};
</script>

<style src="./AQ-component.scss" lang="scss" scoped></style>
