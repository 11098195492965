<template>
  <div class="container">
    <div class="content">
      
       <img src="../../assets/logo_ajc_vert.svg">
      
      <p>Chargement</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "loader-component",
};
</script>

<style src="./loader-component.scss" lang="scss" scoped></style>
